import React from 'react'

const Anchor = props => {
	const {
		children,
		...bypassProps
	} = props

	return (
		<a {...bypassProps}>
			{children}
		</a>
	)
}

export default Anchor
