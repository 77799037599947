import React from 'react'
import {
	Wrapper
} from './TwoColumnLeft.style'

const displayName = 'TwoColumnLeft'

const Component = props => <Wrapper {...props} />

Component.displayName = displayName
export default Component
