import styled from 'styled-components'
import Link from '../../../../../../components/global/Link'
import { type } from '../../../../../../styles/variables'

const StyledLink = styled(Link)`
	font-family: ${type.font.featured};
	font-size: 18px;
	font-style: italic;
`

export {
	StyledLink
}
