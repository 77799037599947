import styled, { css } from 'styled-components'
import media from 'styles/media'

const xxsBreakpoint = 359

const LinksWrapperMain = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	
	@media (max-width: ${xxsBreakpoint}px) {
		flex-direction: column;
		height: 110px;
	}
`

const LinksWrapperFine = styled.div`
	display: flex;
	justify-content: flex-end;

	@media (max-width: ${xxsBreakpoint}px) {
		justify-content: flex-start;
	}
`

const StickyFooterStyles = css`
	${media()} {
		position: sticky;
		bottom: 0;
	}
`

const FooterMain = styled.footer `
	${sticky => sticky && StickyFooterStyles}
`

export {
	FooterMain,
	LinksWrapperFine,
	LinksWrapperMain,
}
