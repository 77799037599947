import styled from 'styled-components'
import media from 'styles/media'
import { spacing } from 'styles/variables'

const itemPadding = spacing.base / 2

const imageGridAreaName = 'image'
const detailGridAreaName = 'detail'

const Wrapper = styled.li`
	padding: ${itemPadding}px 0;

	${media()} {
		padding: 0 ${itemPadding}px;
		width: 35vh;
		max-width: 320px;
		min-width: 200px;

		&:last-child {
			margin-right: ${({endSpacing}) => endSpacing}px;
		}
	}
`

const GridWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-column-gap: ${spacing.base / 2}px;
	grid-template-areas:
		"${imageGridAreaName} ${detailGridAreaName}"
	;

	${media()} {
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr;
		grid-column-gap: unset;
		grid-template-areas:
			"${imageGridAreaName}"
			"${detailGridAreaName}"
		;
	}
`

const ImageWrapper = styled.div`
	grid-area: ${imageGridAreaName};

	${media()} {
		margin-bottom: ${spacing.base / 3}px;
	}
`

const DetailWrapper = styled.div`
	grid-area: ${detailGridAreaName};
`

const NameWrapper = styled.div`
	margin-bottom: ${spacing.base / 4}px;
`

const PositionWrapper = styled.div`

`

const StyledImg = styled.img`
	height: 100%;
	width: 100%;
`

export {
	DetailWrapper,
	GridWrapper,
	ImageWrapper,
	NameWrapper,
	PositionWrapper,
	Wrapper,
	StyledImg,
}
