import React from 'react'
import styled from 'styled-components'
import Heading from 'components/global/Heading'

const displayName = 'Name'

const StyledHeading = styled(Heading)`
	position: relative;
	font-size: 1.5em;
	z-index: 1;
	margin-bottom: 0;
`

const Component = props => (
	<StyledHeading
		level={2}
		{...props}
	/>
)

Component.displayName = displayName
export default Component
