import React from 'react'
import { StyledHeading } from './Position.style'

const displayName = 'Position'

const Component = props => (
	<StyledHeading
		level={3}
		{...props}
	/>
)

Component.displayName = displayName
export default Component
