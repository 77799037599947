import React from 'react'
import { oneOf } from 'prop-types'

const displayName = 'ListElement'

const variants = {
	ORDERED: 'ol',
	UNORDERED: 'ul',
}

const propTypes = {
	element: oneOf(Object.values(variants))
}

const defaultProps = {
	element: 'ul'
}

const Component = props => {

	const {
		element,
		...elementProps
	} = props

	const Element = element === variants.ORDERED ? 'ol' : 'ul'

	return (
		<Element
			{...elementProps}
		/>
	)
}

Component.defaultProps = defaultProps
Component.displayName = displayName
Component.propTypes = propTypes
export default Component
