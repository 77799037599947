import React from 'react';
import AppRouter from './routing/AppRouter'
import './styles/fonts.css'
import GlobalStyles from './styles/globalStyles'
import { GlobalContextProvider } from './context/GlobalContext'

const displayName = 'App'

const Component = () => (
	<GlobalContextProvider>
		<GlobalStyles />
		<AppRouter />
	</GlobalContextProvider>
)

Component.displayName = displayName

export default Component;
