import React from 'react'
import {
	Wrapper
} from './TwoColumnRight.style'

const displayName = 'TwoColumnRight'

const Component = props => <Wrapper {...props} />

Component.displayName = displayName
export default Component
