import styled from 'styled-components'
import { breakpoint, spacing } from 'styles/variables'

const logoGridAreaName = 'brand'
const lineGridAreaName = 'divide'
const introductionTextAreaName = 'introduction'
const contactAreaName = 'contact'
const gapSize = spacing.base
const gapSizeSm = spacing.baseSm
const rightSectionWidthPercent = spacing.rightSectionWidthPercent

const GridWrapper = styled.div`
	padding-top: ${spacing.base * 2}px;
	display: grid;
	grid-column-gap: ${gapSizeSm}px;
	justify-content: flex-end;
	grid-template-columns: 100% 75%;
	grid-template-rows: 1fr 1px 1fr;
	height: 100%;
	grid-row-gap: ${gapSize}px;
	grid-template-areas:
		"${lineGridAreaName} ${introductionTextAreaName}"
		". ${introductionTextAreaName}";
	
	@media (min-width: ${breakpoint}px) {
		padding-top: unset;
		grid-template-columns: auto minmax(20px, 14%) ${100 - rightSectionWidthPercent}%;
		grid-column-gap: ${gapSize}px;
		grid-template-areas:
			"${logoGridAreaName} . ."
			"${logoGridAreaName} ${lineGridAreaName} ${introductionTextAreaName}"
			". . ${introductionTextAreaName}";
	}
`

const LogoGridElement = styled.div`
	display: none;
	align-self: flex-end;
	grid-area: ${logoGridAreaName};
	max-width: 220px;
	justify-self: flex-end;
	margin-bottom: -17px;
	
	@media (min-width: ${breakpoint}px) {
		display: block;
	}
`

const SeparatorLineGridElement = styled.div`
	grid-area: ${lineGridAreaName};
`

const IntroductionTextGridElement = styled.div`
	align-self: flex-start;
	grid-area: ${introductionTextAreaName};
	justify-self: flex-end;
	margin-top: -12px;
	
	@media (min-width: ${breakpoint}px) {
		justify-self: flex-start;
		margin-top: -15px;
	}
`

const ContactGridItem = styled.div`
	align-self: flex-end;
	grid-area: ${contactAreaName};
`

export {
	breakpoint,
	ContactGridItem,
	IntroductionTextGridElement,
	GridWrapper,
	LogoGridElement,
	SeparatorLineGridElement
}
