import styled, { css } from 'styled-components'
import ListElement from './src/ListElement'

const renderUnstyledStyles = render => {
	const styles = css`
		list-style: none;
		padding-left: 0;
		margin-top: 0;
		margin-bottom: 0;
	`
	return render && styles
}

const Wrapper = styled(ListElement)`
	${({variant}) => renderUnstyledStyles(variant === 'unstyled')}
`

export {
	Wrapper
}
