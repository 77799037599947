import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'
import { colors, type } from './variables'
import { reset } from './reset'

const GlobalStyles = createGlobalStyle`
	${normalize()}
	${reset}
	
	* {
		box-sizing: border-box;
	}

	html {
		font-family: '${type.font.default}', sans-serif;
		color: ${colors.white};
		font-size: ${type.baseFontSize}px;
		font-style: normal;
		font-weight: ${type.fontWeight.light};
		line-height: ${type.lineHeight.base};
	}
	
	body {
		background-color: ${colors.black};
		min-width: 320px;
		padding-bottom: calc(env(safe-area-inset-bottom) * 2);
		-webkit-tap-highlight-color: rgba(0,0,0,0);
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	b {
		font-weight: ${type.fontWeight.bold}
	}

	:focus {
		outline-color: ${colors.brand};
	}
`

export default GlobalStyles
