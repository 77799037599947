import styled from 'styled-components'
import media from 'styles/media'
import { spacing } from 'styles/variables'

const Wrapper = styled.ul`
	display: flex;
	justify-content: flex-start;
	flex-direction: column;

	${media()} {
		flex-direction: row;
		margin: 0 -${spacing.base / 2}px;
	}
`

export {
	Wrapper,
}
