import React from 'react'
import { oneOf } from 'prop-types'
import { Wrapper } from './List.style'

const variants = {
	NUMBERED: 'numbered',
	BULLET: 'bullet',
	UNSTYLED: 'unstyled'
}

const variantElements = {
	[variants.NUMBERED]: 'ol',
	[variants.BULLET]: 'ul',
	[variants.UNSTYLED]: 'ul',
}

const propTypes = {
	variant: oneOf(Object.values(variants))
}

const defaultProps = {
	variant: variants.UNSTYLED
}

const List = props => {
	
	const {
		variant
	} = props

	const element = variantElements[variant]
	
	return (
		<Wrapper
			element={element}
			{...props}
		/>
	)
}

List.propTypes = propTypes
List.defaultProps = defaultProps

export default List
