import styled from 'styled-components'
import { gridAreaNames } from '../../config'
import media from 'styles/media'
import { spacing } from 'styles/variables'

const Wrapper = styled.div`
	grid-area: ${gridAreaNames.left};
	
	${media()} {
		padding-right: ${spacing.base}px;
	}
`

export {
	Wrapper
}
