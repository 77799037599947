import React from 'react'
import {
	Link
} from './Link.styles'

const displayName = 'Link'

const Component = props => {
	const {
		children,
		variant,
		...propsBypass
	} = props
	
	const LinkProps = {
		children,
		variant,
		...propsBypass
	}
	
	return <Link {...LinkProps}/>
}

Component.displayName = displayName
export default Component
