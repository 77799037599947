import React, { useState, createContext } from 'react'

const GlobalContext = createContext()

const GlobalContextProvider = props => {

	const {
		children
	} = props

	const contextValue = {
		navigationSticky: useState(false)
	}

	return (
		<GlobalContext.Provider value={contextValue}>
			{children}
		</GlobalContext.Provider>
	)
}

export {
	GlobalContext,
	GlobalContextProvider,
}
