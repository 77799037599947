import styled, { css } from 'styled-components'
import { colors, type } from 'styles/variables'
import media from 'styles/media'

const renderFeaturedStyles = render => {
	const styles = css`
		font-family: ${type.font.featured};
		color: ${colors.brand};
		font-size: 1.7em;
		font-style: italic;
		line-height: 1.1;
		
		${media()} {
			font-size: 2.3em;
		}
	`
	return render && styles
}

const renderStrongStyles = render => {
	const styles = css`
		font-weight: ${type.fontWeight.bold};
		font-size: 1.1em;
	`
	return render && styles
}

const renderFineStyles = render => {
	const styles = css`
		font-size: .9em;
		
		${media()} {
			font-size: 1em;
		}
	`
	return render && styles
}

const Element = styled.p`
	${({variant}) =>  renderFeaturedStyles(variant === 'featured')}
	${({variant}) =>  renderStrongStyles(variant === 'strong')}
	${({variant}) =>  renderFineStyles(variant === 'fine')}

	${({styled}) => styled}
`

export {
	Element
}
