import React from 'react'
import PropTypes from 'prop-types'

const displayName = 'HeadingElement'
const headingLevels = [1, 2, 3, 4, 5, 6]

const propTypes = {
	level: PropTypes.oneOf(headingLevels)
}

const defaultProps = {
	'level': 1
}

const Component = props => {
	const {
		children,
		level,
		...elementProps
	} = props

	const Element = `h${level}`

	return (
		<Element
			{...elementProps}
		>
			{children}
		</Element>
	)
}

Component.propTypes = propTypes
Component.defaultProps = defaultProps
Component.displayName = displayName
export default Component
