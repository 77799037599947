import Home from '../pages/Home'
import Team from '../pages/Team'
import CreativeCapital from '../pages/CreativeCapital'
import Disclaimer from '../pages/Disclaimer'

const routes = [
	{
		path: '/',
		component: Home
	},
	{
		path: '/team',
		component: Team
	},
	{
		path: '/creative-capital',
		component: CreativeCapital
	},
	{
		path: '/disclaimer',
		component: Disclaimer
	}
]

export {
	routes
}
