import React from "react";
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import Anchor from '../Anchor'

const propTypes = {
	external: PropTypes.bool,
	newTab: PropTypes.bool
}

const defaultProps = {
	external: false
}

const RenderedLink = props => {
	const {
		children,
		external,
		newTab,
		to,
		...bypassProps
	} = props

	const newTabProps = newTab && {
		target: '_blank',
		rel: 'noopener noreferrer'
	}

	const commonProps = {
		children,
		...bypassProps,
		...newTabProps
	}

	const anchorProps = {
		...commonProps,
		href: to
	}

	const linkProps = {
		...commonProps,
		to
	}

	return external ? <Anchor {...anchorProps} /> : <RouterLink {...linkProps} />
}

RenderedLink.propTypes = propTypes
RenderedLink.defaultProps = defaultProps

export default RenderedLink
