import React from 'react'
import { StyledLink } from './NavigationLink.style'

const displayName = 'NavigationLink'

const Component = props => {
	
	const {
		children
	} = props
	
	return (
		<StyledLink
			variant="featured"
			{...props}
		>
			{children}
		</StyledLink>
	)
}

Component.displayName = displayName
export default Component
