import React from 'react'
import Lottie from 'react-lottie'
import animationData from './arrowAnimationData.json'
import {
	AnimationWrapper,
	DiagramWrapper,
	TextItem,
	TextOperator,
	TextWrapper,
	Wrapper
} from './CreativeCapitalDiagram.style'

const displayName = 'CreativeCapitalDiagram'

const lottieOptions = {
	loop: false,
	autoplay: true,
	animationData: animationData,
	renderer: 'svg',
};

const Component = props => (
	<Wrapper>
		<DiagramWrapper>
			<AnimationWrapper>
				<Lottie
					options={lottieOptions}
				/>
			</AnimationWrapper>
			<TextWrapper>
				<TextItem>
					Early access opportunities
				</TextItem>
				<TextOperator>+</TextOperator>
				<TextItem>
					Highest-quality deals
				</TextItem>
				<TextOperator>+</TextOperator>
				<TextItem>
					Command over the outcome
				</TextItem>
				<TextOperator>=</TextOperator>
				<TextItem>
					Best-in-class consumer brand platform
				</TextItem>
			</TextWrapper>
		</DiagramWrapper>
	</Wrapper>
)

Component.displayName = displayName
export default Component
