import React, { forwardRef, memo, useEffect, useRef, useState, useImperativeHandle } from 'react'
import { Video } from './Video.style'

const displayName = 'Video'

const Component = memo(forwardRef((props, forwaredRef) => {
	const [ playPromise , setPlayPromise ] = useState()
	const videoRef = useRef()
	useImperativeHandle(forwaredRef, () => videoRef.current)
	
	const {
		play,
		resetAtPause,
		resetAtPlay,
	} = props
	
	useEffect(() => {
		const currentVideo = videoRef.current
		const videoIsPaused = currentVideo.paused
		const videoShouldBeReset = (!play && resetAtPause) || (play && resetAtPlay)

		if (videoShouldBeReset) {
			videoRef.current.currentTime = 0
		}

		if (videoIsPaused && play) {
			setPlayPromise(currentVideo.play())
			return
		}

		if (!videoIsPaused && !play) {
			if (playPromise) {
				playPromise.then(() => currentVideo.pause())
				return
			}
			currentVideo.pause()
		}

	}, [play, resetAtPlay, resetAtPause, playPromise])
	
	const componentProps = {
		ref: videoRef,
		...props,
	}
	
	return <Video {...componentProps} />
}))

Component.displayName = displayName

export default Component
