import styled from 'styled-components'
import { spacing } from 'styles/variables'
import media from 'styles/media'

const CopyWrapper = styled.div `
	display: flex;
	justify-content: center;
	flex-direction: column;

	> p:not(:first-of-type) {
		margin-top: ${spacing.baseSm}px;
	}

	> p:first-of-type {
		margin-top: ${spacing.baseSm / 2}px;
	}
`

const LeftCopyWrapper = styled.div`
	${media()} {
		padding-left: ${({vw}) => vw(10)}px;
	}
`

export {
	CopyWrapper,
	LeftCopyWrapper
}
