import styled, { css } from 'styled-components'
import { spacing } from 'styles/variables'
import media from 'styles/media'
import swutch from 'swutch'

const rightSectionWidthPercent = spacing.rightSectionWidthPercent
const leftColumnWidthPercent = rightSectionWidthPercent
const rightColumnWidthPercent = 100 - rightSectionWidthPercent
const leftAreaName = 'left'
const rightAreaName = 'right'

const base = css`
	display: grid;
	
	${media()} {
		grid-template-areas: "${leftAreaName} ${rightAreaName}";
		grid-template-columns: ${leftColumnWidthPercent}% ${rightColumnWidthPercent}%;
	}
`

const rightOnly = css`
	display: grid;
	grid-template-areas: "${rightAreaName}";

	${media()} {
		grid-template-areas: ". ${rightAreaName}";
		grid-template-columns: ${leftColumnWidthPercent}% ${rightColumnWidthPercent}%;
	}
`
const standard = css`
	${base}
	grid-auto-columns: auto;
	grid-template-areas: "${leftAreaName} ${rightAreaName}";
`

const stacked = css`
	${base}
	grid-template-areas:
		"${leftAreaName}"
		"${rightAreaName}"
	;
	grid-row-gap: ${spacing.base}px;

	${media()} {
		grid-row-gap: unset;
	}
`

const alignItemsCenterStyles = css`
	${media()} {
		align-items: center;
	}
`

const Wrapper = styled.div`
	${
		({variant}) => swutch([
			[variant === 'stacked', stacked],
			[variant === 'rightOnly', rightOnly]
		], standard)
	}
	${({alignContent}) => alignContent === 'center' && alignItemsCenterStyles}
	${({styled}) => styled}
`

export {
	Wrapper
}
