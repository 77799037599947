import React from 'react'
import Link from '../../../../components/global/Link'
import BrandLogo from '../../../../components/global/BrandLogo'

const displayName = 'HomeLogo'

const Component = props => (
	<Link
		to="/"
	>
		<BrandLogo />
	</Link>
)

Component.displayName = displayName
export default Component
