const headerCopy = 'THIS WEBSITE (THE “SITE”) IS INTENDED SOLELY FOR ACCREDITED INVESTORS (AS DEFINED IN RULE 501(a) UNDER THE SECURITIES ACT OF 1933).'

const copy = [
	'The Site is operated by IMA Investor, LLC (the “Company”), which provides investment advice with respect to several United States funds (collectively, the “Funds”). The Company is not a registered investment adviser under the laws of any jurisdiction, and no regulatory authority has examined or approved the information, materials, services, and other content (including, but not limited to, all text, images, graphics and other visuals) available on or though the Site (the “Content”).',
	'The Company provides the Site and the Content for informational purposes only. Use of and access to the Site and the Content is subject to this disclaimer and all applicable laws. The Company makes no representation or warranty with respect to the accuracy or completeness of the Content, and past performance of any investment is no guarantee of future return or future performance of any other investment, including an investment in any Fund. Investors are cautioned that they may lose all or a portion of their investment in any Fund. The Company disclaims all liability for any investment decisions made by its readers on the basis of the Content.',
	'Neither the Site nor any of the Content is intended to constitute an offer to sell, or the solicitation of an offer to purchase, any security, as any such offer and/or sale may be made only by definitive offering documentation. Any offer or solicitation with respect to any securities that may be issued by any Fund will be made only by means of definitive offering memoranda and in accordance with the relevant securities and other laws of applicable jurisdictions. THE COMPANY STRONGLY RECOMMENDS THAT ANY INVESTMENT DECISION BE DISCUSSED WITH AN ATTORNEY, A FINANCIAL ADVISOR, A BROKER-DEALER, OR A MEMBER OF A FINANCIAL REGULATORY BODY.'
]

export {
	headerCopy,
	copy
}
