import { bool } from 'prop-types'
import { Container } from './Container.style'

const displayName = 'Container'

const propTypes = {
	fillHeight: bool,
	paddingBottom: bool,
	paddingTop: bool,
}

Container.displayName = displayName
Container.propTypes = propTypes
export default Container
