import React from 'react'
import { Main } from './Main.styles'
import useViewUnit from 'use-view-unit'

const displayName = 'Main'

const Component = props => {
	const {
		children,
		fullHeight,
		alignCenter
	} = props

	const { vh } = useViewUnit()
	
	return (
		<Main
			fullHeight={fullHeight}
			vh={vh}
			alignCenter={alignCenter}
		>
			{children}
		</Main>
	)
}

Component.displayName = displayName
export default Component
