import styled from 'styled-components'
import { gridAreaNames } from '../../config'

const Wrapper = styled.div`
	grid-area: ${gridAreaNames.right};

	${({styled}) => styled}
`

export {
	Wrapper
}
