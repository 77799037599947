import styled, { css } from 'styled-components'
import { breakpoint } from '../../../../styles/variables'

const NavigationHeaderHeight = 133.27
const NavigationHeaderHeightSm = 93.27
const NavigationFooterHeight = 124
const NavigationFooterHeightSm = 82

const renderFullHeightStyles = (fullHeight, vh) => {
	const styles = css`
		display: flex;
		align-items: center;
		min-height: calc(${vh()}px - ${NavigationHeaderHeightSm + NavigationFooterHeightSm}px);

		@media (min-width: ${breakpoint}px) {
			min-height: calc(${vh()}px - ${NavigationHeaderHeight + NavigationFooterHeight}px);
		}

		@media (max-width: 359px) {
			min-height: calc(${vh()}px - ${NavigationHeaderHeight + 110}px);
		}
	`
	return fullHeight && styles
}

const Main = styled.main`
	${({fullHeight, vh}) => renderFullHeightStyles(fullHeight, vh)}
`

export {
	Main
}
