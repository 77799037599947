import styled, { css } from 'styled-components'
import { breakpoint, spacing } from '../../../styles/variables'

const padding = spacing.base
const paddingSm = spacing.baseSm
const maxWidth = spacing.containerMaxWidth
const alignContentOptions = {
	center: 'center'
}

const defaultStyles = css`
	margin-left: auto;
	margin-right: auto;
	flex-grow: 1;
	width: 100%;
	max-width: ${maxWidth}px;
	padding-left: ${paddingSm}px;
	padding-right: ${paddingSm}px;
	padding-top: ${({paddingTop}) => paddingTop && `${paddingSm}px`};
	padding-bottom: ${({paddingBottom}) => paddingBottom && `${paddingSm}px`};
	
	@media (min-width: ${breakpoint}px) {
		padding-left: ${padding}px;
		padding-right: ${padding}px;
		padding-top: ${({paddingTop}) => paddingTop && `${padding}px`};
		padding-bottom: ${({paddingBottom}) => paddingBottom && `${padding}px`};
	}
`

const fillHeightStyles = css`
	@media (min-width: ${breakpoint}px) {
		display: flex;
		min-height: 100%;
	}
`

const centerContentStyles = css`
	@media (min-width: ${breakpoint}px) {
		align-items: center;
	}
`

const Container = styled.div`
	${defaultStyles}
	${({fillHeight}) => fillHeight && fillHeightStyles}
	${({alignContent}) => alignContent === alignContentOptions.center && centerContentStyles}
`

export {
	Container
}
