import React from 'react'
import Container from 'components/global/Container'
import CreativeCapitalParagraph from './src/CreativeCapitalParagraph'
import CreativeCapitalDiagram from './src/CreativeCapitalDiagram'
import { TwoColumnWrapper, TwoColumnLeft, TwoColumnRight } from 'templates/TwoColumn'
import {
	featuredCopy,
	paragraphsCopy
} from './data'

const displayName = 'CreativeCapital'

const Component = props => (
	<Container
		paddingBottom
		paddingTop
	>
		<TwoColumnWrapper
			alignContent="center"
			variant="stacked"
		>
			<TwoColumnLeft>
				<CreativeCapitalDiagram />
			</TwoColumnLeft>
			<TwoColumnRight>
				<CreativeCapitalParagraph
					variant="featured"
				>
					{featuredCopy}
				</CreativeCapitalParagraph>
				{
					paragraphsCopy.map((paragraphCopy, key) => (
						<CreativeCapitalParagraph
							key={key}
						>
							{paragraphCopy}
						</CreativeCapitalParagraph>
					))
				}
			</TwoColumnRight>
		</TwoColumnWrapper>
	</Container>
)

Component.displayName = displayName
export default Component
