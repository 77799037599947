import styled, { css } from 'styled-components'
import { fluidRange, position } from 'polished'
import { colors, type, spacing } from 'styles/variables'
import Paragraph from 'components/global/Paragraph'
import media from 'styles/media'

const textSpacing = 4

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	
	${media()} {
		margin-bottom: unset;
	}
`

const Image = styled.img`
	width: 100%;
	max-width: 500px;
	
	${media()} {
		width: calc(100% - ${spacing.base * 2}px);
	}
`

const DiagramWrapper = styled.div `
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
`

const AnimationWrapper = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
	${position(0)}
`

const TextWrapper = styled.div`
	text-align: center;
	padding-top: 12vw;
	padding-bottom: 12vw;

	@media (min-width: 920px) {
		padding-top: 100px;
		padding-bottom: 100px;
	}
`

const textSpacingStyles = css`
	padding-top: ${textSpacing}px;
	padding-bottom: ${textSpacing}px;
	margin-bottom: 0;

	${fluidRange(
		{
			prop: 'padding-top',
			fromSize: `${textSpacing / 2}px`,
			toSize: `${textSpacing}px`,
		},
		'320px',
		`${450 + 40}px`,
	)}

	${fluidRange(
		{
			prop: 'padding-bottom',
			fromSize: `${textSpacing / 2}px`,
			toSize: `${textSpacing}px`,
		},
		'320px',
		`${450 + 40}px`,
	)}
`

const TextItem = styled(Paragraph)`
	font-family: ${type.font.featured};
	font-size: 22px;
	font-style: italic;
	${textSpacingStyles}

	${fluidRange(
		{
			prop: 'font-size',
			fromSize: '10px',
			toSize: '20px',
		},
		'320px',
		`${450 + 40}px`,
	)}

	&:last-child {
		color: ${colors.brand};
	}
`

const TextOperator = styled(Paragraph)`
	${textSpacingStyles}
`

export {
	AnimationWrapper,
	DiagramWrapper,
	Image,
	TextItem,
	TextOperator,
	TextWrapper,
	Wrapper,
}
