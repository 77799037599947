import styled, { css } from 'styled-components'
import { breakpoint, spacing } from 'styles/variables'
import List from 'components/global/List'
import ListItem from 'components/global/ListItem'
import media from 'styles/media'
import { TwoColumnRight } from 'templates/TwoColumn'

const brandWidth = 100
const brandWidthiPhone5 = 65

const stickyWrapperStyles = css`
	${media()} {
		position: sticky;
		top: 0;
	}
`

const Wrapper = styled.nav`
	${({sticky}) => sticky && stickyWrapperStyles}
	z-index: 1;
`

const BrandWrapper = styled.div`
	width: ${brandWidth}px;
	
	@media (min-width: ${breakpoint}px) {
		visibility: ${({hide}) => hide ? 'hidden' : null};
		pointer-events: ${({hide}) => hide ? 'none' : null};
	}
	
	@media (max-width: 359px) {
		width: ${brandWidthiPhone5}px;
	}
`

const StyledTwoColumnRight = styled(TwoColumnRight)`
	justify-self: flex-end;
	align-self: flex-end;
	
	@media (min-width: ${breakpoint}px) {
		justify-self: flex-start;
	}
`

const StyledList = styled(List)`
	display: flex;
	margin-left: -${spacing.base / 4}px;
	margin-right: -${spacing.base / 4}px;
`

const StyledListItem = styled(ListItem)`
	padding: 0 ${spacing.base / 4}px;
`

const TwoColumnWrapperStyles = css`
	align-items: baseline;
`

export {
	BrandWrapper,
	StyledList,
	StyledListItem,
	StyledTwoColumnRight,
	TwoColumnWrapperStyles,
	Wrapper,
}
