import React from 'react'
import Container from 'components/global/Container'
import HomeLogo from './src/HomeLogo'
import {
	IntroductionTextGridElement,
	GridWrapper,
	LogoGridElement,
	SeparatorLineGridElement
} from './Home.styles'
import BackgroundVideo from './src/BackgroundVideo'
import IntroductionText from './src/IntroductionText'
import SeparatorLine from '../../components/global/SeparatorLine'

const displayName = 'Home'

const Component = props => (
	<>
		<BackgroundVideo />
		<Container
			paddingBottom
			paddingTop
			fillHeight
			alignContent="center"
		>
			<GridWrapper>
				<LogoGridElement>
					<HomeLogo />
				</LogoGridElement>
				<SeparatorLineGridElement>
					<SeparatorLine />
				</SeparatorLineGridElement>
				<IntroductionTextGridElement>
					<IntroductionText />
				</IntroductionTextGridElement>
			</GridWrapper>
		</Container>
	</>
)

Component.displayName = displayName
export default Component
