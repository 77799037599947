import React from 'react'
import { withRouter } from 'react-router-dom'
import NavigationHeader from './src/NavigationHeader'
import NavigationFooter from './src/NavigationFooter'
import Main from './src/Main'

const displayName = 'TemplateMain'

const hideBrandLocations = [
	'/'
]

const fullHeightLocations = [
	'/',
	'/creative-capital',
	'/disclaimer',
	'/team'
]

const alignCenterLocations = [
	'/',
	'/creative-capital',
	'/disclaimer',
	'/team'
]

const Component = withRouter(props => {
	
	const {
		children,
		location
	} = props
	
	const hideBrand = hideBrandLocations.includes(location.pathname)
	const fullHeight = fullHeightLocations.includes(location.pathname)
	const alignCenter = alignCenterLocations.includes(location.pathname)
	
	return (
		<>
			<NavigationHeader
				hideBrand={hideBrand}
			/>
			<Main
				fullHeight={fullHeight}
				alignCenter={alignCenter}
			>
				{children}
			</Main>
			<NavigationFooter />
		</>
	)
})

Component.displayName = displayName
export default Component
