const team = [
	{
		firstName: 'DOUG',
		lastName: 'JACOB',
		position: 'Co-Founder',
		video: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots%2Fdoug_v2.mp4?alt=media&token=016639a1-43d5-41d4-9ae2-c1676cd29f9e',
			poster: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots%2Fdoug_v2_poster.jpg?alt=media&token=5b94abc2-34db-46e7-8f61-b08510735244',
		},
		link: 'https://www.linkedin.com/in/doug-jacob-74b17058/',
	},
	{
		firstName: 'MICHAEL',
		lastName: 'LASTORIA',
		position: 'Co-Founder',
		video: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots%2Fmichael.mp4?alt=media&token=add7bf6e-f827-446c-9ac1-207ab0080ef3',
			poster: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots%2Fmichael_poster.jpg?alt=media&token=eddb2c9e-f755-4432-9140-ef1a1519def9',
		},
		link: 'https://www.linkedin.com/in/michaellastoria/',
	},
	{
		firstName: 'GARRETT',
		lastName: 'SCHREIBER',
		position: 'Partner',
		video: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots%2Fgarrett.mp4?alt=media&token=d7d2b93f-703b-41b6-9f64-903e7f758698',
			poster: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots%2Fgarrett_poster.jpg?alt=media&token=1558928a-a0b8-49fc-ab7f-fb7d59eb80d2',
		},
		link: 'https://www.linkedin.com/in/garrettschreiber/',
	},
	{
		firstName: 'ARNAVA',
		lastName: 'ASEN',
		position: 'Partner/Strategic Director',
		video: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots%2Farnava.mp4?alt=media&token=5aefe5b5-66d6-4dea-b863-ab90b3a69edf',
			poster: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots%2Farnava_poster.jpg?alt=media&token=494ce74a-58c4-4972-8725-27ce5275f0fe',
		},
		link: 'https://www.linkedin.com/in/arnava-asen-13960518/',
	},
	{
		firstName: 'SHEHAN',
		lastName: 'ABRAHAMS',
		position: 'Partner/Creative Director',
		video: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots%2Fshehan.mp4?alt=media&token=232dd3b1-66cf-4416-aaa7-205335b81788',
			poster: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots%2Fshehan_poster.jpg?alt=media&token=891d5168-e1af-42b8-b6fb-08efccd00f18',
		},
		link: 'https://www.linkedin.com/in/shehanabrahams/',
	},
	{
		firstName: 'JED',
		lastName: 'ABBI',
		position: 'Content Creator',
		video: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots%2Fjed.mp4?alt=media&token=70aa38cd-7242-4ddf-9cc9-cfd383e35b44',
			poster: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots%2Fjed_poster.png?alt=media&token=fbbc4889-25f4-41bc-8dd5-93b615574180',
		},
		link: 'https://www.linkedin.com/in/jed-abbi-4bb8a6150/',
	},
	{
		firstName: 'LAUREN',
		lastName: 'GORSKY',
		position: 'Art Director',
		image: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots%2Flauren_800.jpg?alt=media&token=db044cd0-5242-48a5-b423-5d3f78ce8b06',
			alt: 'Lauren Gorsky'
		},
		link: 'https://www.linkedin.com/in/lauren-gorsky-52398090/',
	},
	{
		firstName: 'CHELSEA',
		lastName: 'HERNANDEZ',
		position: 'Art Director',
		image: {
			src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/team_headshots%2Fchelsea_800.jpg?alt=media&token=81ca333d-990e-474b-a4d7-6a2b320de9b1',
			alt: 'Chelsea Hernandez'
		},
		link: 'https://www.linkedin.com/in/chelsea-hernandez-754615143/',
	},
]

export {
	team,
}
