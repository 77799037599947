import styled from 'styled-components'
import { colors } from '../../../styles/variables'

const displayName = 'SeparatorLine'

const Component = styled.div`
	height: 1px;
	background-color: ${colors.brand};
`

Component.displayName = displayName
export default Component
