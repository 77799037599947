import styled, { createGlobalStyle } from 'styled-components'
import media from 'styles/media'
import { spacing } from 'styles/variables'

const GlobalStyles = createGlobalStyle`
	body::-webkit-scrollbar {
		width: 0;
	}
	html {
		scrollbar-width: none;
	}
`

const ScrollOuter = styled.div`
	padding-left: ${spacing.baseSm}px;
	padding-right: ${spacing.baseSm}px;

	${media()} {
		height: ${({height}) => height}px;
		display: flex;
		align-items: center;
	    position: sticky;
	    top: calc(100% - ${({top}) => top}px);
		overflow-y: visible;
		overflow-x: auto;
		&::-webkit-scrollbar {
			width: 0;
		}
		scrollbar-width: none;
		width: 100vw;
		padding-left: ${({gutterSize}) => gutterSize}px;
		padding-right: ${({gutterSize}) => gutterSize}px;
	}
`
ScrollOuter.displayName = "ScrollOuter"

const BodyHeightDictator = styled.div`
	${media()} {
		height: ${({height}) => height}px;
	}
`
BodyHeightDictator.displayName = "BodyHeightDictator"

export {
	BodyHeightDictator,
	GlobalStyles,
	ScrollOuter,
}
