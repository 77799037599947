import React from 'react'
import styled from 'styled-components'
import Paragraph from 'components/global/Paragraph'
import { spacing } from 'styles/variables'

const displayName = 'CreativeCapitalParagraph'

const StyledParagraph = styled(Paragraph)`
	:not(:last-child) {
		margin-bottom: ${spacing.base}px;
	}

	:last-child {
		margin-bottom: 0;
	}
`

const Component = props => {
	return <StyledParagraph {...props} />
}

Component.displayName = displayName
export default Component
