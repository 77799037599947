import { css } from 'styled-components'

const reset = css`
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	ul,
	fieldset {
		margin: 0;
	}

	ul,
	fieldset {
		padding: 0;
	}

	ul {
		list-style: none;
	}

	fieldset {
		border: 0;
	}
`

export {
	reset
}
