import React, { forwardRef } from 'react'
import { arrayOf, shape, string } from 'prop-types'
import TeamListItem from '../TeamListItem'
import {
	Wrapper,
} from './TeamList.style'

const displayName = 'TeamList'

const propTypes = {
	team: arrayOf(
		shape({
			name: string,
			position: string,
			video: shape({
				src: string,
				poster: string,
			})
		}).isRequired
	)
}

const Component = forwardRef((props, ref) => {

	const {
		endSpacing,
		scrollAmount,
		team,
		scrollOuterRef,
	} = props

	return (
		<Wrapper
			ref={ref}
			scrollAmount={scrollAmount}
		>
			{
				team.map((teamItem, key) => (
					<TeamListItem
						endSpacing={endSpacing}
						key={key}
						scrollOuterRef={scrollOuterRef}
						teamMember={teamItem}
					/>
				))
			}
		</Wrapper>
	)
})

Component.displayName = displayName
Component.propTypes = propTypes
export default Component
