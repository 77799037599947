const isInViewport = element => {
	if (!element) {
		return
	}

	const rect = element.getBoundingClientRect()
	const windowHeight = window.innerHeight
	const windowWidth = window.innerWidth
	const verticalInViewport = rect.top <= windowHeight && (rect.top + rect.height) >= 0
	const horizontalInViewport = rect.left <= windowWidth && (rect.left + rect.width) >= 0

	return verticalInViewport && horizontalInViewport
}

export default isInViewport
