const colors = {
	black: '#000000',
	white: '#ffffff',
	brand: '#f89d65'
}

const type = {
	baseFontSize: 13,
	fontWeight: {
		thin: 100,
		extraLight: 200,
		light: 300,
		normal: 400,
		demiBold: 600,
		bold: 700,
		heavy: 900
	},
	lineHeight: {
		base: 1.2
	},
	font: {
		default: 'Helvetica Neue LT Pro Extended',
		featured: 'Canela'
	}
}

const spacing = {
	base: 40,
	baseSm: 20,
	containerMaxWidth: 1400,
	rightSectionWidthPercent: 50
}

const breakpoint = 920

export {
	breakpoint,
	colors,
	type,
	spacing
}
