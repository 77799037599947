import React, { useEffect, useRef } from 'react'
import { backgroundVideo as data } from '../../data'
import { BackgroundVideo } from './BackgroundVideo.style'

const displayName = 'BackgroundVideo'

const Component = props => {
	const videoRef = useRef()
	
	useEffect(() => {
		videoRef.current.play()
	}, [])
	
	return (
		<BackgroundVideo
			ref={videoRef}
			loop
			muted
			preload="auto"
			poster={data.poster}
			autoPlay="autoplay"
			playsInline
		>
			<source
				src={data.src}
				type="video/mp4"
			/>
		</BackgroundVideo>
	)
}

Component.displayName = displayName
export default Component
