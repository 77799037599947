const featuredCopy = `
	As creatives with capital, we identify breakout brands early in their
	lifecycle; as capital with creative firepower, we have a real stake in the
	direction of brands we invest in.
`

const paragraphsCopy = [
	`
		We leverage the best of creative agencies and investment funds, and add
		to it the expertise of operators who have scaled and exited brands with
		replicable success.
	`,
	`
		With this model, we're investing in and building category leaders:
		realizing a more meaningful vision through more visionary means.
	`
]

export {
	featuredCopy,
	paragraphsCopy
}
