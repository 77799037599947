import React from 'react'

const ListItem = props => {
	
	const {
		children,
		...propsBypass
	} = props
	
	return (
		<li {...propsBypass}>
			{children}
		</li>
	)
}

export default ListItem
