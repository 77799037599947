import styled, { css } from 'styled-components'
import HeadingElement from './src/HeadingElement'
import { type } from 'styles/variables'

const renderSmStyles = render => {
	const styles = css `
		font-size: ${type.baseFontSize}px;
	`
	return render && styles
}

const Heading = styled(HeadingElement)`
	${({size}) => renderSmStyles(size === 'sm')}
`

export {
	Heading
}
