import styled from 'styled-components'
import { position } from 'polished'

const BackgroundVideo = styled.video`
	position: fixed;
	height: 100vh;
	width: 100vw;
	z-index: -1;
	object-fit: cover;
	${position(0)}
`

export {
	BackgroundVideo
}
