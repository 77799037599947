import styled from 'styled-components'
import RenderedLink from './src/RenderedLink'
import { css } from 'styled-components'
import { breakpoint, colors, type } from '../../../styles/variables'

const featuredFontSize = 20;

const variants = {
	featured: 'featured'
}

const defaultStyles = css`
	text-decoration: underline;
	color: ${colors.brand};
	text-decoration: none;
	transition: color 200ms ease-out;
	
	@media (hover:hover) {
		&:hover {
			color: ${colors.white};
		}
	}
`

const featuredStyles = css`
	font-weight: ${type.fontWeight.bold};
	
	@media (min-width: ${breakpoint}px) {
		font-size: ${featuredFontSize}px;
	}
`

const Link = styled(RenderedLink)`
	${defaultStyles}
	${({variant}) => variant === variants.featured && featuredStyles}
`

export {
	Link
}
