import React from 'react'
import {
	StyledLink
} from './FineLink.style'

const displayName = 'FineLink'

const Component = props => {

	const {
		children
	} = props

	return (
		<StyledLink {...props}>
			{children}
		</StyledLink>
	)
}

Component.displayName = displayName
export default Component
