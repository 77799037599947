import React from 'react'
import { headerCopy, copy } from './data.js'
import Container from 'components/global/Container'
import Paragraph from 'components/global/Paragraph'
import Heading from 'components/global/Heading'
import {
	CopyWrapper,
	LeftCopyWrapper
} from './Disclaimer.style'
import useViewUnit from 'use-view-unit'
import { TwoColumnWrapper, TwoColumnLeft, TwoColumnRight } from 'templates/TwoColumn'

const displayName = 'Disclaimer'

const Component = props => {
	const { vw } = useViewUnit()
	return (
		<Container>
			<TwoColumnWrapper variant="stacked">
				<TwoColumnLeft>
					<LeftCopyWrapper vw={vw}>
						<Paragraph>
							<b>{headerCopy}</b>
						</Paragraph>
					</LeftCopyWrapper>
				</TwoColumnLeft>
				<TwoColumnRight>
					<CopyWrapper>
						<Heading size="sm">DISCLAIMER</Heading>
						{copy.map((copyItem, key) => (
							<Paragraph
								variant="fine"
								key={key}
							>
								{copyItem}
							</Paragraph>
						))}
					</CopyWrapper>
				</TwoColumnRight>
			</TwoColumnWrapper>
		</Container>
	)
}
Component.displayName = displayName
export default Component
