import styled from 'styled-components'
import Link from '../../../../../../components/global/Link'

const StyledLink = styled(Link)`
	font-size: 10px;
`

export {
	StyledLink
}
