import styled from 'styled-components'
import Heading from 'components/global/Heading'
import { colors, type } from 'styles/variables'

const StyledHeading = styled(Heading)`
	font-family: '${type.font.featured}', serif;
	color: ${colors.brand};
	font-style: italic;
	font-weight: ${type.fontWeight.thin};
	margin-bottom: 0;
	font-size: 1.3em;
`

export {
	StyledHeading,
}
