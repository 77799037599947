import React, { useContext } from 'react'
import { bool } from 'prop-types'
import useMatchMedia from '@strawberrymilk/use-match-media'
import Container from 'components/global/Container'
import BrandLogo from 'components/global/BrandLogo'
import Link from 'components/global/Link'
import NavigationLink from '../NavigationLink'
import { TwoColumnWrapper, TwoColumnLeft } from 'templates/TwoColumn'
import { breakpoint } from 'styles/variables'
import {
	BrandWrapper,
	StyledList,
	StyledListItem,
	StyledTwoColumnRight,
	TwoColumnWrapperStyles,
	Wrapper,
} from './NavigationHeader.styles'
import { GlobalContext } from 'context/GlobalContext'

const displayName = 'NavigationHeader'

const propTypes = {
	hideBrand: bool
}

const Component = props => {
	const sticky = useContext(GlobalContext).navigationSticky[0]
	
	const {
		hideBrand,
	} = props

	const isLargeBreakpoint = useMatchMedia(`(min-width: ${breakpoint}px)`)
	
	return (
		<Wrapper
			sticky={sticky}
		>
			<Container
				paddingBottom
				paddingTop
			>
				<TwoColumnWrapper
					styled={TwoColumnWrapperStyles}
				>
					<TwoColumnLeft>
						<BrandWrapper
							hide={hideBrand}
						>
							<Link
								to="/"
							>
								<BrandLogo />
							</Link>
						</BrandWrapper>
					</TwoColumnLeft>
					<StyledTwoColumnRight>
						<StyledList>
							<StyledListItem>
								<NavigationLink
									to="/creative-capital"
									variant="featured"
								>
									CREATIVE CAPITAL
								</NavigationLink>
							</StyledListItem>
							{
								!isLargeBreakpoint &&
								<StyledListItem>
									<NavigationLink
										to="/team"
										variant="featured"
									>
										TEAM
									</NavigationLink>
								</StyledListItem>
							}
						</StyledList>
					</StyledTwoColumnRight>
				</TwoColumnWrapper>
			</Container>
		</Wrapper>
	)
}

Component.displayName = displayName
Component.propTypes = propTypes
export default Component
