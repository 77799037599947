import styled from 'styled-components'
import Link from '../../../../components/global/Link'
import { breakpoint } from '../../../../styles/variables'

const featuredFontSize = 18;

const StyledLink = styled(Link)`
	font-size: 12px;
	
	@media (min-width: ${breakpoint}px) {
		font-size: ${featuredFontSize}px;
	}
`

export {
	StyledLink
}
