import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styles/variables'

const displayName = 'BrandLogo'

const Svg = styled.svg`
	display: block;
	fill: ${colors.brand};
	overflow: visible;
	width: 100%;
`

const Component = props => {
	
	const svgProps = {
		xmlns: 'http://www.w3.org/2000/svg',
		viewBox: '0 0 1053.29 561.07'
	}
	
	return (
		<Svg {...svgProps}>
			<title>&vest</title>
			<path
				d="M121.19,564a45.89,45.89,0,0,1-32.94-14.2,44,44,0,0,1-12.36-31.38c0-9.43,3.49-25.16,20-37l11.83-8.4,52.38,72.33S148.62,564,121.2,564m121-21.5c15.14-26.7,15.14-59.11,15-61.06H190L112.24,368c-18.8-28.31.69-52,22.46-52,16.81,0,30.4,13.25,30.4,29.59a27.38,27.38,0,0,1-.67,6.25,38.65,38.65,0,0,1-.89,3.83c-1.81,6.38-6.17,12.38-12.7,17.82l32.53,46.39c75.18-54.79,41-160.35-50.94-160.35C74,259.46,9.31,324.27,61.49,409l8.8,13.59-11.08,8.87C28.36,456,13.35,484.55,13.35,518.58c0,30,10.77,54.92,32.89,76.1S94.5,626.2,126.1,626.2c21.45,0,41.62-6.64,61.79-20.36l11-7.46,15.26,22.38H285.3L237.91,550Z"
				transform="translate(-13.35 -259.46)"
			/>
			<path
				d="M228.16,650.48l94.53,160.61h67l54.2-160.61H376.4l-27.49,88.67-52.32-88.67Z"
				transform="translate(-13.35 -259.46)"
			/>
			<path
				d="M671,766.77c-5.49,16.48-17.63,30.06-36.13,39.31s-43.35,14.46-76.59,14.46c-76,0-119.38-27.75-119.38-85.56,0-54.63,41.63-85.27,112.73-85.27,69.95,0,121.4,23.41,120.24,102.32h-159c0,11.28,11.85,28.62,43.64,28.62,18.79,0,29.78-4,37.58-13.88Zm-72-51.16c-1.44-17.05-17.92-27.74-42.77-27.74-25.15,0-39.6,7.8-43.36,27.74Z"
				transform="translate(-13.35 -259.46)"
			/>
			<path
				d="M746.69,751.45c-41.91-4.33-54.05-21.1-54.05-48.55,0-37.29,34.68-53.19,105.21-53.19,49.13,0,103.19,9,104.63,53.76H821c0-4-1.44-7.51-5.49-9.82s-10.7-4-21.39-4c-21.68,0-25.72,4.91-25.72,11,0,4,3.75,8.38,19.36,9.83l68.79,6.07c39,3.47,53.77,20.81,53.77,47.69,0,40.76-35.27,56.37-111.58,56.37-75.15,0-107.81-15.61-110.12-55.5h85c.86,6.36,4,10.4,9,12.72,5.2,2.31,12.43,2.89,20.52,2.89,18.5,0,24-4.92,24-12.14,0-4.63-2.31-9-9.83-9.83Z"
				transform="translate(-13.35 -259.46)"
			/>
			<path
				d="M919.81,656.07h33.53V605.2h72.26v50.87h39v45.09h-39V757c0,14.74,6.07,15.6,21.68,15.6,5.78,0,12.43-.28,19.37-.57v42.2a383.77,383.77,0,0,1-52.61,3.46c-43.93,0-60.7-8.38-60.7-51.44v-65H919.81Z"
				transform="translate(-13.35 -259.46)"
			/>
		</Svg>
	)
}

Component.displayName = displayName
export default Component
