import React from 'react'
import Paragraph from '../../../../components/global/Paragraph'
import { copy } from '../../data'

const displayName = 'IntroductionText'

const Component = props => (
	<Paragraph
		variant="featured"
	>
		{copy.introduction}
	</Paragraph>
)

Component.displayName = displayName
export default Component
