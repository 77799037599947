const copy = {
	introduction: 'A consumer brand investment and incubation platform founded by time-tested entrepreneurs and top-tier creatives.'
}

const backgroundVideo = {
	src: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/andvest_home_background_20200302.mp4?alt=media&token=88483d7a-383b-4e22-a09c-50363b7a6c02',
	poster: 'https://firebasestorage.googleapis.com/v0/b/andvest-prod.appspot.com/o/andvest_home_background_20200302_poster.jpg?alt=media&token=69642c65-5552-437e-858a-c0e9219acff4'
}

export {
	copy,
	backgroundVideo
}
